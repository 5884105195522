<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Subscription</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Subscription
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <router-link v-if="checkIsAccessible('subscription', 'create')" :to="{name:'subscription-create'}"
                               class="btn btn-primary font-weight-bolder font-size-sm">
                    <span class="svg-icon svg-icon-md svg-icon-white"></span>
                    Create New Subscription
                  </router-link>&nbsp;
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Subscription Info" v-model="search.info" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-btn
                    @click="searchSubscriptions"
                    class="mt-1 btn btn-primary"
                >
                  <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                  Search
                </v-btn>
              </v-col>

            </div>

            <div class="table-responsive" style="table-layout: fixed">
              <table class="table">
                <thead>
                <th>Name</th>
                <th>Period</th>
                <th>Price</th>
                <th class="text-center">Offer Available</th>
                <th>Offer Dates</th>
                <th class="text-center">Status</th>
                <th>Action</th>
                </thead>
                <tbody v-if="subscriptions.length > 0">
                    <tr v-for="(subscription,index) of subscriptions" :key="index">
                      <td>
                    <router-link :to="{name:'subscription-update', params:{id:subscription.id}}" class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">{{subscription.title }}
                    </router-link>
                  </td>
                      <td>
                    {{ `${subscription.period} ${subscription.period_unit ? subscription.period_unit : ''}` }}
                  </td>
                      <td>
                    Rs.{{ subscription.price }}
                  </td>
                      <td class="text-center">
                    <i class="fas" :class="subscription.offer_enabled?'fa-check text-success':'fa-ban text-danger'"></i>
                    <span v-if="subscription.offer_percentage"> {{subscription.offer_percentage}}%</span>
                  </td>
                      <td>
                    <span v-if="subscription.offer_enabled">
                    Start Date: <span
                        class="badge badge-primary">{{ subscription.formatted_offer_start_date }}</span><br>
                    End Date: <span class="badge badge-primary">{{ subscription.formatted_offer_end_date }}</span>
                      </span>
                    <span v-else>-</span>
                  </td>
                      <td class="text-center">
                    <i class="fas" :class="subscription.is_active?'fa-check text-success':'fa-ban text-danger'"></i>
                  </td>
                      <td>
                    <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                    >
                      <template v-slot:button-content>
                        <slot>
                          <span>
                            <i class="flaticon-more-1"></i>
                          </span>
                        </slot>
                      </template>
                      <!--begin::Navigation-->
                      <div class="navi navi-hover ">

                        <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('subscription', 'edit')">
                          <a href="#" class="navi-link"
                             @click="editSubscription(subscription.id)">
                            <span class="navi-icon">
                              <i class="fas fa-pen "></i>
                            </span>
                            <span class="navi-text">  Edit</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('subscription', 'delete') && subscription.user_count == 0">
                          <a href="#" class="navi-link"
                             @click="deleteSubscription(subscription.id)">
                            <span class="navi-icon">
                              <i class="fas fa-trash "></i>
                            </span>
                            <span class="navi-text">  Delete</span>
                          </a>
                        </b-dropdown-text>


                      </div>
                      <!--end::Navigation-->
                    </b-dropdown>
                  </td>
                    </tr>
                </tbody>
                <tbody v-if="subscriptions.length == 0">
                <tr>
                    <td class="text-center" colspan="7">
                      <strong>No Data Found</strong>
                    </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import SubscriptionService from "@/services/subscription/SubscriptionService";

const subscriptionService = new SubscriptionService();
export default {
  name: "Index",
  data() {
    return {
      search: {},
      loading: false,
      subscriptions: [],
      page: null
    }
  }, mounted() {
    this.getSubscriptions();
  },
  methods: {
    searchSubscriptions() {
      this.getSubscriptions();
    },
    getSubscriptions() {
      this.loading = true;
      subscriptionService.paginate({}, this.page).then(response => {
        this.loading = false
        this.subscriptions = response.data.data
      }).catch(error => {
        this.loading = false
      }).finally(this.loading = false)
    },
    editSubscription(id) {
      this.$router.push({name: "subscription-update", params: {id: id}})
    },
    deleteSubscription(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            this.loading = true;
            subscriptionService.delete(id).then(response => {
              this.$snotify.success('Subscription delete successfully')
              this.getSubscriptions();
            }).catch(error => {
              this.$snotify.error('Something went wrong. Please try again later')
              this.loading = false;
            }).finally(this.loading = false)
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>